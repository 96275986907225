import React from 'react';
import { Router } from '@reach/router';
import { PublicLayout } from '../components/Shared/PublicLayout';
import { PrivateRoute } from '../components/Shared/PrivateRoute';
import { NotFoundRedirect } from '../components/Shared/Redirects/NotFoundRedirect';
import { ProfileRedirect } from '../components/Shared/Redirects/ProfileRedirect';
import { UserDetail } from '../components/User/UserDetail';

export default function Profile() {
  return (
    <PublicLayout>
      <Router basepath="/profile">
        <PrivateRoute
          component={UserDetail}
          path="/:id"
          roles={['Administrator', 'Organizer', 'User']}
        />
        <ProfileRedirect path="/" />
        <NotFoundRedirect path="*" />
      </Router>
    </PublicLayout>
  );
}
