import { RouteComponentProps } from '@reach/router';
import { navigate } from 'gatsby-link';
import React, { useEffect } from 'react';
import { getUser, isLoggedIn } from '../../../services/auth';
import { PageLoader } from '../Loaders/PageLoader';

export function ProfileRedirect(_: RouteComponentProps) {
  useEffect(() => {
    if (isLoggedIn()) {
      navigate(`/profile/${getUser().id}`, { replace: true });
      return;
    }

    navigate('/404', { replace: true });
  }, []);

  return <PageLoader />;
}
