import React from 'react';
import { Loader } from './Loader';

export function PageLoader() {
  return (
    <section className="flex flex-1 h-96 py-4 items-center justify-center">
      <Loader />
    </section>
  );
}
